import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-scroll'
import { Navigation } from '../lib/navigation';
import  { HiMenuAlt3 } from "react-icons/hi"
import Pdf from '../stmt.pdf';

const Header = () => {
  const [fixed, setFixed] = useState(false);
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setHeight(open ? ref.current.clientHeight : 0);
    }
  }, [open]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 100) {
        setFixed(true);
      } else {
        setFixed(false);
      }

      return () => {
        window.removeEventListener("scroll");
      };
    });
  }, []);

  return (
    <header className={`${
      !fixed
        ? "bg-white py-4 shadow-xl"
        : "bg-brand-primary-100/90 py-3 shadow-lg"} fixed w-full z-50 transition-all duration-300 ease-in-out`}>
      <nav className='max-w-8xl mx-auto px-2.5 flex items-center justify-between'>
        {/* site logo */}
        <Link to="home" className={`${fixed ? 'text-white' : 'text-brand-primary-100'} font-bold text-2xl text-left cursor-pointer italic`} spy={true} smooth={true} offset={-300} duration={500}>
          Skytrain
        </Link>
        {/* site navigation */}
        <div className={`${fixed ? 'text-white' : 'text-brand-primary-100'} hidden md:flex items-center font-bold`}>
          {Navigation.map((item) => (
            <span className={`${!fixed ? 'hover:text-white' : 'hover:text-brand-primary-100'} relative block text-sm lg:text-lg px-2 py-1 lg:px-2.5 lg:py-1.5 cursor-pointer bg-transparent transition-all duration-300 ease-linear overflow-hidden group`} key={item.id}>
              <span className={`absolute -left-full group-hover:left-0 top-0 w-full h-full -z-10 transition-all duration-500 ease-in-out ${!fixed ? 'bg-brand-primary-100' : 'bg-white'}`}></span>
              <Link to={item.href} spy={true} smooth={true} offset={-100} duration={500}>{item.name}</Link>
            </span>
          ))}
          <span className={`${!fixed ? 'hover:text-white' : 'hover:text-brand-primary-100'} relative block text-sm lg:text-lg px-2 py-1 lg:px-2.5 lg:py-1.5 cursor-pointer bg-transparent transition-all duration-300 ease-linear overflow-hidden group`}>
              <span className={`absolute -left-full group-hover:left-0 top-0 w-full h-full -z-10 transition-all duration-500 ease-in-out ${!fixed ? 'bg-brand-primary-100' : 'bg-white'}`}></span>
              <a href={Pdf} target = "_blank" rel="noreferrer">Capability Statement</a>
            </span>
        </div>
        {/* mobile menu btn */}
        <button onClick={() => setOpen(!open) } className={`${fixed ? 'text-white' : 'text-brand-primary-100'} md:hidden text-2xl`}>
            <HiMenuAlt3 />
        </button>


      </nav>
      {/* mobile menu */}
      <div
        className="relative z-50 md:hidden overflow-hidden transition-all ease-in-out duration-500"
        style={{ height: `${open ? `${height}px` : "0px"}` }}>
        <div
          ref={ref}
          className={`${
            fixed ? "text-white" : "text-brand-primary-100"
          } flex flex-col font-semibold text-xs uppercase whitespace-nowrap gap-y-2 px-4 pt-5 nav-link pb-3`}>
          {Navigation.map((item) => (
            <span className={`${!fixed ? 'hover:text-white' : 'hover:text-brand-primary-200'} sm:w-1/2 relative block text-sm lg:text-lg px-2 py-1 cursor-pointer bg-transparent transition-all duration-300 ease-linear overflow-hidden group`} key={item.id}>
              <span className={`absolute -left-full group-hover:left-0 top-0 w-full h-full -z-10 transition-all duration-500 ease-in-out ${!fixed ? 'bg-brand-primary-100' : 'bg-white'}`}></span>
              <Link to={item.href} spy={true} smooth={true} offset={-300} duration={500}>{item.name}</Link>
            </span>
          ))}
        </div>
      </div>
    </header>
  )
}

export default Header
