import React from 'react'
import SectionTitle from './SectionTitle'
import { ImPlus, ImArrowUp} from "react-icons/im"
import {ComparisonTable, ExampleData} from "../lib/data"

const SmartFreight = () => {
  return (
    <section id='system' className='max-w-8xl mx-auto px-2.5 py-20 mb-5'>
      <div data-aos="fade-up" className='mb-10'>
        <h2 className='text-2xl md:text-3xl text-brand-primary-200 font-bold text-center capitalize mb-5'>
        Smart freight system
        </h2>
        <span className='block w-14 md:w-20 mx-auto h-0.5 md:h-1 bg-brand-primary-100 mb-5'></span>
        <p className='text-center text-sm md:text-lg'>
        A new suspended intelligent transportation system that
        creates technical and cost barriers
        </p>
      </div>

      {/* System Overview */}
      <Overview />
      {/* patents */}
      <Patents />
      {/* Application */}
      <Application />
      {/* System Features */}
      <Features />
      {/* Transportation */}
      <Transportation />
      {/* Smart Loading/Unloading and Transshipment */}
      <Transshipment />
      {/* Smart Loading/Unloading and Transshipment2 */}
      <Transshipment2 />
      {/* Automated Customs Border Inspection */}
      <Inspection />
      {/* Freight Rates Comparison */}
      <Comparison />
      <Comparison2 />
      {/* Freight Rates Comparison - Example */}
      <Example />
    </section>
  )
}

// overview
const Overview = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'System Overview'} /> 
      <div data-aos="fade-up">
        <p className='indent-4 mb-5 text-sm md:text-base'>
          The intelligent suspended container freight system has the characteristics of flexible laying methods, exclusive right of
          way, large carrying capacity, safety and reliability, energy saving and environmentally friendly, and full automation. It is a
          brand-new freight mode. It mainly solves the problem of multimodal container transport within various transportation
          distances, It is the optimal choice for building freight trunk lines, freight branch lines, and solving the seamless connection
          of the "middle mile" and "last mile".
        </p>
        <img className='w-full max-h-44' src={require('../assets/img/overview2.png')} alt="" />
        <img className='w-full' src={require('../assets/img/overview.jpg')} alt="" />
      </div>
    </div>
  )
}

// patents
const Patents = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'Patents'} />      
      <div data-aos="fade-up" className='flex flex-col md:flex-row items-start gap-4 '>
        <div className='flex-1'>
          <div className='flex items-start gap-2 mb-2'>
            <span className='w-2 h-2 mt-1.5 rounded-full bg-brand-primary-100 '></span>
            <p className='flex-1'>
              Independent research and development, owns complete
              proprietary intellectual property rights.
            </p>
          </div>
          <div className='flex items-start gap-2 mb-2'>
            <span className='w-2 h-2 mt-1.5 rounded-full bg-brand-primary-100 '></span>
            <p className='flex-1'>
              72 patents have been declared, including 38 inventions, 34
              utility models, and 4 system core patents that have been
              verified by the State Intellectual Property Office and are
              characterized by novelty, creativity, and practicability
            </p>
          </div>
        </div>
        <img className='max-h-[300px] rounded-lg shadow-md  md:w-1/2 mx-auto' src={require('../assets/img/patent1.png')} alt="" />
      </div>
    </div>
  )
}

// Application
const Application = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'Application'} />
      <div data-aos="fade-up" className='flex flex-col md:flex-row gap-8'>
        <div className='bg-white rounded-lg text-sm sm:text-base shadow-md p-4'>
          <p>
            The application of the system include sealand transit, rail-water transit, district-port
            transit, and major logistics channels. By
            creating designated tracks in the air and
            replacing the traditional routing, the system
            achieves the seamless connection
            between the port quayside bridge and the
            outer storage yard, as well as integrating
            the industrial zone and the commercial
            zone. It improves the transportation
            efficiency, expands the utility of storage
            yard space, and achieves separation of
            passengers and freight usage.
          </p>
        </div>
        <img className='md:w-1/2' src={require('../assets/img/aplication.png')} alt="" />
      </div>
    </div>
  )
}

// System Features
const Features = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'System Features'} />
      <div data-aos="fade-up" className='flex flex-col md:flex-row gap-5 md:gap-10 mb-5'>
        <div className='bg-white rounded-lg text-sm sm:text-base shadow-md p-4'>
          Low transportation cost. The energy
          consumption per kilometer is no more
          than 3kW per hour in the range of
          10km~200km transportation distance.
          This is extremely competitive in both
          freight rates and timeliness.
        </div>
        <img className='flex-1 md:w-3/5 rounded-md shadow-md' src={require('../assets/img/features1.png')} alt="" />
      </div>
      <div className='flex md:gap-10 relative justify-between w-full'>
        <ImArrowUp className='absolute left-[47%] sm:left-[48%] lg:left-[48.5%] -translate-x-1/2 text-3xl md:text-4xl text-gray-600 animate-bounce animate-infinite animate-ease-linear animate-normal' />
        <ImPlus className='absolute left-1/2 top-3/4 sm:top-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl md:text-4xl text-gray-600' />
        <img className='w-2/5 rounded-md shadow-md' src={require('../assets/img/features2.jpg')} alt="" />
        <img className='w-2/5 rounded-md shadow-md' src={require('../assets/img/features3.jpg')} alt="" />
      </div>
    </div>
  )
}

// Transportation
const Transportation = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'Transportation Features'} />
      <div data-aos="fade-up" className='flex flex-col md:flex-row gap-5 md:gap-10 mb-5'>
        <div className='bg-white rounded-lg text-sm sm:text-base shadow-md p-4'>
          During transit, the bottom of the container is carrying the
          weight, which is consistent with how containers are
          carried in road transportation and railway transportation,
          The conditions meet the requirements of container
          transportation standards.
        </div>
        <img className='flex-1 md:w-2/5 rounded-md shadow-md' src={require('../assets/img/transport1.png')} alt="" />
      </div>
      <div className='grid grid-cols-2 items-stretch gap-4 md:gap-10 justify-between w-full'>
        <img className='rounded-md shadow-md max-h-80' src={require('../assets/img/transport3.png')} alt="" />
        <img className='rounded-md shadow-md max-h-80' src={require('../assets/img/transport2.png')} alt="" />
      </div>
    </div>
  )
}

// Smart Loading/Unloading and Transshipment
const Transshipment = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'Smart Loading/Unloading and Transshipment'} />
      <div data-aos="fade-up" className='flex flex-col md:flex-row gap-5 md:gap-10 mb-5'>
        <div className='bg-white rounded-lg text-sm sm:text-base shadow-md p-4'>
          <p className='mb-2'>
            The container keyhole is automatically identified by the
            loading and unloading equipment. The transshipment
            equipment operates independently. Track line is laid
            behind the yard, and the AGV (Automated Guided
            Vehicles) vehicle transfers the container to the bottom of
            the empty track line for transshipment loading and
            unloading.
          </p>
          <img className='w-full max-h-80' src={require('../assets/img/transship1.jpg')} alt="" />
        </div>
        <img className='flex-1 md:w-1/2 rounded-md shadow-md' src={require('../assets/img/transship2.jpg')} alt="" />
      </div>
    </div>
  )
}

// Smart Loading/Unloading and Transshipment2
const Transshipment2 = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'Smart Loading/Unloading and Transshipment'} />
      <div data-aos="fade-up" className='flex flex-col md:flex-row gap-5 md:gap-10 mb-5'>
        <div className='flex flex-col justify-between bg-white rounded-lg text-sm sm:text-base shadow-md p-4'>
          <p className='mb-2'>
            By utilizing AGV logistics robots, gantry cranes,
            and hoisting machinery, the system can
            accomplish seamless and automated loading,
            unloading and transshipment operations with
            trucks, freight trains, yards, and ships.
          </p>
          <img className='w-full max-h-80' src={require('../assets/img/transship3.png')} alt="" />
        </div>
        <img className='flex-1 md:w-1/2' src={require('../assets/img/transship4.png')} alt="" />
      </div>
    </div>
  )
}

// Automated Customs Border Inspection
const Inspection = () => {
  return (
    <div className='mb-8'>
      <SectionTitle title={'Automated Customs Border Inspection'} />
      <div data-aos="fade-up" className='flex flex-col md:flex-row gap-5 md:gap-10 mb-5'>
        <div className='flex-1 flex flex-col justify-between bg-white rounded-lg text-sm sm:text-base shadow-md p-4'>
          <p className='mb-2'>
          The container can directly pass through the customs machine inspection channel
          to achieve seamless docking, and <span className='text-red-600'>the container is 100% automatically
          inspected</span>, replacing the traditional operation as shown in the pictures on the
          right.
          </p>
          <img className='w-full max-h-80' src={require('../assets/img/inspection1.png')} alt="" />
        </div>
        <div className='md:w-2/5 space-y-10 flex flex-col items-end'>
          <img className='max-h-64 w-full md:max-w-64 rounded-md shadow-md' src={require('../assets/img/inspection3.jpg')} alt="" />
          <img className='max-h-64 w-full md:max-w-64 rounded-md shadow-md' src={require('../assets/img/inspection2.jpg')} alt="" />
        </div>
      </div>
    </div>
  )
}

// Freight Rates Comparison
const Comparison = () => {
  return (
    <div className='mb-5'>
      <SectionTitle title={'Freight Rates Comparison'} />
      <div data-aos="fade-up" className='flex-1 flex flex-col justify-between bg-white rounded-lg text-sm sm:text-base shadow-md p-4 mb-4'>
      According to the "China Road Freight Market Research" and the report of the Price Testing Center of the National
      Development and Reform Commission, the freight rates of road container transportation has been 0.5~0.65 RMB/
      ton per km since 2017. Our smart freight system can be as low as 0.25 RMB/ton per km with 10% profit margin,
      not even counting savings in environmental protection and reduction in traffic congestion. Once reached
      economies of scale, profit margin can increase to 15%.
      </div>
      <div className='flex flex-col md:flex-row items-stretch gap-4 md:gap-10 justify-between w-full'>
        <img className='md:w-2/5 max-h-80' src={require('../assets/img/comparison1.jpg')} alt="" />
        <img className='md:w-2/5 max-h-80' src={require('../assets/img/comparison2.jpg')} alt="" />
      </div>
    </div>
  )
}

// Freight Rates Comparison2
const Comparison2 = () => {
  return (
    <div className='mb-5'>
      <SectionTitle title={'Freight Rates Comparison'} />
      <div className='flex-1 flex flex-col justify-between bg-white rounded-lg text-sm sm:text-base shadow-md p-4 mb-4'>
      Both road and railway transportation have some shortcomings and limitations. Road transportation has
      high energy consumption. Railway transportation has low flexibility. Our smart freight system can be
      used as a supplement to road and railway, eliminating the disadvantage of the existing transportation
      system to achieve multimodal transportation.
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full bg-white border text-center text-sm font-light">
                <thead
                  className="border-b bg-brand-primary-100 font-medium text-white">
                  <tr>
                    <th scope="col" className=" px-6 py-4">Project</th>
                    <th scope="col" className=" px-6 py-4">Road</th>
                    <th scope="col" className=" px-6 py-4">Rail</th>
                    <th scope="col" className=" px-6 py-4">Smart Freight</th>
                  </tr>
                </thead>
                <tbody>
                  {ComparisonTable.map((tr, i) => (
                    <tr className="border-b" key={i}>
                      <td className="whitespace-nowrap  px-6 py-4">{tr.project}</td>
                      <td className="whitespace-nowrap  px-6 py-4">{tr.road}</td>
                      <td className="whitespace-nowrap  px-6 py-4">{tr.rail}</td>
                      <td className="whitespace-nowrap  px-6 py-4">{tr.smart}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Freight Rates Comparison - Example
const Example = () => {
  return (
    <div>
      <SectionTitle title={'Freight Rates Comparison - Example'} />
      <div data-aos="fade-up" className="flex flex-col mb-5">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full border-b-4 border-blue-800 bg-white text-center text-sm font-light">
                <thead className="border-y-4 border-blue-800 font-medium text-brand-primary-100">
                  <tr>
                    <th scope="col" className="px-6 py-4">Project</th>
                    <th scope="col" className="px-6 py-4">Parameters</th>
                  </tr>
                </thead>
                <tbody>
                  {ExampleData.map((tr,i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap px-6 py-2">{tr.project}</td>
                    <td className="whitespace-nowrap px-6 py-2">{tr.parameters}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white rounded-lg text-sm sm:text-base shadow-md p-4 mb-4'>
        <h5 className='bg-brand-primary-100 text-white font-semibold text-lg w-52 text-center mb-3'>Social Benefits</h5>
        <p>
        Through the implementation of this project, more than 830 acres of land outside the port can be freed up.
        Reduction in carbon emissions can reach 700,000 tons per year, as well as reduction in traffic congestion. It
        helps to re-plan and rectify logistics chaos.
        </p>
      </div>
    </div>
  )
}

export default SmartFreight
