import React from "react";
import {
  marketData,
  industryData,
  competitorsTable,
  comparativeTable,
} from "../lib/data";
import { IoIosContacts } from "react-icons/io";

const Market = () => {
  return (
    <section id="market" className="max-w-8xl mx-auto px-2.5 py-20 mb-5">
      <div data-aos="fade-up" className="mb-10">
        <h2 className="text-2xl md:text-3xl text-brand-primary-200 font-bold text-center capitalize mb-5">
          Market Analysis and Trend
        </h2>
        <span className="block w-14 md:w-20 mx-auto h-0.5 md:h-1 bg-brand-primary-100 mb-5"></span>
        <p className="text-center text-sm md:text-lg">
          Substantial market potentia
        </p>
      </div>

      <div className="grid items-start md:grid-cols-2 gap-10">
        {marketData.map((item, i) => (
          <div data-aos="fade-up" key={i} className="flex lg:w-3/5 mx-auto items-start gap-4">
            {item.icon}
            <div className="flex-1">
              <h3 className="font-semibold text-brand-primary-100 text-lg mb-2">
                {item.title}
              </h3>
              <p className="text-sm sm:text-base">{item.text}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Industry Competitive Analysis */}
      <Industry />
      {/* Comparative Analysis of Products with the Same Capacity */}
      <ComparativeTable />
      {/* Strategic Development Plan */}
      <Plan />
      {/* Short-term Development Plan */}
      <TimeLine />
      {/* Business model and target customers */}
      <Model />
    </section>
  );
};

// Industry Competitive Analysis
const Industry = () => {
  return (
    <>
      <div className="flex items-center py-10">
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
        <h3 className="md:text-lg text-brand-primary-200 font-bold capitalize whitespace-nowrap shadow-md bg-white py-2 px-4 border-2 border-brand-primary-100 border-dashed rounded-full">
          Industry Competitive Analysis
        </h3>
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
      </div>

      <div className="grid lg:grid-cols-2 items-center gap-4">
        <div data-aos="fade-up">
          <h3 className="text-center text-brand-primary-100 font-bold text-xl mb-4 capitalize">
            Barrier to Entry
          </h3>
          <div className="grid grid-cols-2">
            {industryData.map((item, i) => (
              <div key={i}>
                {/* head */}
                <div
                  className={`flex items-center mb-1 ${
                    item.id % 2 !== 0 ? "" : "flex-row-reverse mt-10"
                  }`}>
                  <h4 className="flex-1 w-full text-center text-lg font-semibold text-brand-primary-100 shadow-300 py-2 rounded">
                    Competitive {item.id}
                  </h4>
                  <IoIosContacts className="text-7xl text-brand-primary-100" />
                </div>
                <p className="pl-6">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div data-aos="fade-up" className="overflow-x-auto">
          <div className="inline-block min-w-full lg:w-full">
            <div className="overflow-hidden">
              <table className="min-w-full lg:w-full bg-white border text-center text-sm font-light">
                <thead className="border-b bg-brand-primary-100 font-medium text-white">
                  <tr>
                    <th scope="col" className=" px-6 py-4">
                      Category
                    </th>
                    <th scope="col" className=" px-6 py-4">
                      BYD (Car)
                    </th>
                    <th scope="col" className=" px-6 py-4">
                      Traditional Air Rail
                    </th>
                    <th scope="col" className=" px-6 py-4">
                    Skytaxi Air Rail
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competitorsTable.map((tr, i) => (
                    <tr className="border-b" key={i}>
                      <td className="px-1 py-2">{tr.category}</td>
                      <td className="px-1 py-2">{tr.byd}</td>
                      <td className="px-1 py-2">{tr.traditional}</td>
                      <td className="px-1 py-2">{tr.skytaxi}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Comparative Analysis of Products with the Same Capacity
const ComparativeTable = () => {
  return (
    <>
      <div className="flex items-center py-10 sm:mb-5">
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
        <h3 className="md:text-lg text-brand-primary-200 font-bold capitalize sm:whitespace-nowrap shadow-md bg-white py-2 px-4 border-2 border-brand-primary-100 border-dashed rounded-full text-center">
          Comparative Analysis of Products with the Same Capacity
        </h3>
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
      </div>
      {/* table */}
      <div className="overflow-x-auto">
        <div data-aos="fade-up" className="inline-block min-w-full lg:w-full">
          <div className="overflow-hidden">
            <table className="min-w-full lg:w-full bg-white border text-center text-sm font-light">
              <thead className="border font-medium">
                <tr>
                  <th scope="col" className=" px-6 py-4">
                    Category
                  </th>
                  <th scope="col" className=" px-6 py-4">
                    Traditional Tram
                  </th>
                  <th scope="col" className=" px-6 py-4">
                    Traditional Airbus
                  </th>
                  <th scope="col" className=" px-6 py-4">
                  Skytrain
                  </th>
                </tr>
              </thead>
              <tbody>
                {comparativeTable.map((tr, i) => (
                  <tr className="" key={i}>
                    <td className="px-1 border py-2">{tr.category}</td>
                    <td className="px-1 border py-2">{tr.tram}</td>
                    <td className="px-1 border py-2">{tr.airbus}</td>
                    <td className="px-1 border py-2">{tr.skytaxi}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

// Strategic Development Plan
const Plan = () => {
  return (
    <>
      <div className="flex items-center py-10">
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
        <h3 className="md:text-lg text-brand-primary-200 font-bold capitalize sm:whitespace-nowrap shadow-md bg-white py-2 px-4 border-2 border-brand-primary-100 border-dashed rounded-full text-center">
          Strategic Development Plan
        </h3>
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
      </div>
      {/* plan */}
      <div data-aos="fade-up" className="relative">
        <h4 className="bg-white font-semibold text-lg text-brand-primary-100 text-center w-52 mx-auto capitalize py-2 px-4 rounded-full shadow-200">
          startegic plan
        </h4>
        <img
          className="absolute left-1/2 -translate-x-1/2 -top-16 rotate-90"
          src={require("../assets/img/bracekt.png")}
          alt=""
        />

        <div className="w-full grid grid-cols-2 mt-20 text-center">
          <div className="mx-auto">
            <h4 className="font-bold sm:text-xl mb-1 text-brand-primary-100">
              Expansion Direction:{" "}
            </h4>
            <p className="text-sm sm:text-base">
              Parks, and tourist areas, ports and wharves
            </p>
          </div>
          <div className="mx-auto md:w-1/2">
            <h4 className="font-bold sm:text-xl mb-1 text-brand-primary-100">
              Overall development goals:{" "}
            </h4>
            <p className="text-sm sm:text-base">
              Creating competitive advantage in the field of smart suspended
              transportation and become a global leader in monorail technology.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className="relative w-full sm:w-4/5 grid grid-cols-2 ml-auto justify-end mt-20 text-center">
        <img
          className="absolute w-10 sm:w-auto left-1/2 md:left-[65%] -top-36 sm:-top-48 rotate-90"
          src={require("../assets/img/bracekt.png")}
          alt=""
        />
        <div className="ml-auto md:w-1/2">
          <h4 className="font-bold sm:text-lg mb-1 text-brand-primary-100">
            Mid-term business goals:
          </h4>
          <p className="text-sm sm:text-base">
            expand construction line to 200KM in 5 years, revenue of 10 billion
            RMB, profit of 2 billion RMB
          </p>
        </div>
        <div className="ml-auto md:w-1/2">
          <h4 className="font-bold sm:text-lg mb-1 text-brand-primary-100">
            Mid-term business goals:
          </h4>
          <p className="text-sm sm:text-base">
            expand construction line to 2000KM in 10 years, revenue of 100
            billion RMB, profit of 20 billion RMB.
          </p>
        </div>
      </div>
    </>
  );
};

// time line
const TimeLine = () => {
  return (
    <>
      <div className="flex items-center py-10">
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
        <h3 className="md:text-lg text-brand-primary-200 font-bold capitalize sm:whitespace-nowrap shadow-md bg-white py-2 px-4 border-2 border-brand-primary-100 border-dashed rounded-full text-center">
          Short-term Development Plan
        </h3>
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
      </div>
      <div className="w-full h-full">
        <div className="relative wrap overflow-hidden h-full">
          <div className="border-2-2 absolute left-1/2 border-opacity-20 border-brand-primary-100 h-full border-2 border-dashed" />
          {/* right timeline */}
          <div data-aos="fade-up" className="flex justify-between items-start w-full right-timeline">
            <div className="order-1 w-1/2" />
            <div className="order-1 rounded-lg w-1/2">
              <span className="text-xl mb-2 pl-6">2023</span>
              <h3 className="mb-3 md:w-3/5 font-bold text-white bg-gray-600 px-6 py-2 sm:text-xl">
                Complete the test line and expand more than 2 lines
              </h3>
            </div>
          </div>
          {/* left timeline */}
          <div data-aos="fade-up" className="flex justify-between flex-row-reverse w-full left-timeline">
            <div className="order-1 w-1/2" />
            <div className="order-1 w-1/2 text-right">
              <span className="text-xl mb-2 px-6">2023</span>
              <h3 className="mb-3 md:w-1/2 text-center ml-auto font-bold text-white bg-brand-yellow-100 px-6 py-2 sm:text-xl">
                R & D center
              </h3>
              <p className="md:w-1/2 text-left ml-auto text-sm font-medium leading-snug tracking-wide text-brand-primary-200 px-2 sm:px-6">
                Complete the joint research and development center with
                Chongqing University and Southwest Jiaotong University.
              </p>
            </div>
          </div>
          {/* right timeline */}
          <div data-aos="fade-up" className="flex justify-between items-start w-full right-timeline">
            <div className="order-1 w-1/2" />
            <div className="order-1 rounded-lg w-1/2">
              <span className="text-xl mb-2 pl-6">2024</span>
              <h3 className="mb-3 md:w-10/12 font-bold text-white bg-gray-600 px-6 py-2 sm:text-xl">
              Demonstration line Open
              </h3>
              <p className="md:w-1/2 text-left text-sm font-medium leading-snug tracking-wide text-brand-primary-200 sm:first-letter px-2 sm:px-6">
              50 kilometers of commercial lines for
              national high-tech enterprises
              </p>
            </div>
          </div>
          {/* left timeline */}
          <div data-aos="fade-up" className="flex justify-between flex-row-reverse w-full left-timeline">
            <div className="order-1 w-1/2" />
            <div className="order-1 w-1/2 text-right">
              <span className="text-xl mb-2 px-6">2023-2025</span>
              <h3 className="mb-3 md:w-11/12 ml-auto font-bold text-white bg-gray-600 px-6 py-2 sm:text-xl text-left">
              Complete demonstration line
              </h3>
              <p className="md:w-3/4 text-left ml-auto text-sm font-medium leading-snug tracking-wide text-brand-primary-200 px-2 sm:px-6">
                Complete 150+KM route.
                Layout key equipment manufacturing and control
                the core industrial chain.
                Sales income of more than 7 billion RMB and
                profit of 1.4 billion RMB.
              </p>
            </div>
          </div>
          {/* right timeline */}
          <div data-aos="fade-up" className="flex justify-between items-start w-full right-timeline">
            <div className="order-1 w-1/2" />
            <div className="order-1 rounded-lg w-1/2">
              <span className="text-xl mb-2 pl-6">2025-2026</span>
              <h3 className="mb-3 md:w-3/5 font-bold text-white bg-brand-yellow-100 px-6 py-2 sm:text-xl">
              Public listing
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Business model and target customers
const Model = () => {
  return (
    <>
      <div className="flex items-center py-10">
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
        <h3 className="md:text-lg text-brand-primary-200 font-bold capitalize sm:whitespace-nowrap shadow-md bg-white py-2 px-4 border-2 border-brand-primary-100 border-dashed rounded-full text-center">
          Business model and target customers
        </h3>
        <span className="w-1/2 block border-2 border-brand-primary-100 border-dashed"></span>
      </div>
      <div className="w-full h-full">
        <div className="relative wrap overflow-hidden h-full">
          <div className="border-2-2 absolute left-1/2 border-opacity-20 border-brand-primary-100 h-full border-2 border-dashed" />
          {/* left timeline */}
          <div data-aos="fade-up" className="flex justify-between flex-row-reverse w-full left-timeline md:pr-5">
            <div className="order-1 w-1/2" />
            <div className="order-1 w-1/2 text-right">
              <span className="text-xl mb-2 px-6">2023</span>
              <h3 className="mb-3 md:w-1/2 text-center ml-auto font-bold text-white bg-brand-yellow-100 px-6 py-2 sm:text-xl">
              EPC Service
              </h3>
              <p className="md:w-3/4 text-left ml-auto text-sm font-medium leading-snug tracking-wide text-brand-primary-200 px-2 sm:px-6">
              It mainly includes general contracting services for all
              equipment such as track equipment,
              electromechanical equipment, communication
              signals, switch station equipment, vehicles, etc., as
              well as services such as operation maintenance and
              technical consultation.
              </p>
            </div>
          </div>
          {/* right timeline */}
          <div data-aos="fade-up" className="flex justify-between items-start w-full right-timeline md:pl-5">
            <div className="order-1 w-1/2" />
            <div className="order-1 rounded-lg w-1/2">
              <span className="text-xl mb-2 pl-6">2023</span>
              <h3 className="mb-3 md:w-3/5 font-bold text-white bg-gray-600 px-6 py-2 sm:text-xl text-right">
              Target customers
              </h3>
              <p className="md:w-3/4 text-left text-sm font-medium leading-snug tracking-wide text-brand-primary-200 px-2 sm:px-6">
              Governments, investment enterprises, central
              enterprises, local state-owned enterprises, real
              estate developers, park operators and
              developers, tourist attractions, port terminal
              operations and investment companies.
              </p>
            </div>
          </div>
          {/* left timeline */}
          <div data-aos="fade-up" className="flex justify-between flex-row-reverse w-full left-timeline md:pr-5">
            <div className="order-1 w-1/2" />
            <div className="order-1 w-1/2 text-right">
              <span className="text-xl mb-2 px-6">2023</span>
              <h3 className="mb-3 md:w-11/12 text-center ml-auto font-bold text-white bg-gray-600 px-6 py-2 sm:text-xl">
              Route feasibility study and
              planning design
              </h3>
              <p className="md:w-3/4 text-left ml-auto text-sm font-medium leading-snug tracking-wide text-brand-primary-200 px-2 sm:px-6">
              Provide route network planning, passenger flow
              forecast, feasibility study, comprehensive
              planning and design consulting services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Market;
