export const Navigation = [
  {
    id: 1,
    href: "rail",
    name: "Skytaxi Air Rail"
  },
  {
    id: 2,
    href: "tram",
    name: "Skytaxi-PRT smart tram"
  },
  {
    id: 3,
    href: "system",
    name: "Smart freight system"
  },
  {
    id: 4,
    href: "market",
    name: "Market Analysis and Trend"
  }
]