import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai"
import { BsPlayCircle } from "react-icons/bs"

const Hero = () => {
  const [active, setActive] = useState(false);
  const [modalNum, setModal] = useState(1);

  const handleOpen = (num) => {
    setActive(true);
    setModal(num)
  }

  return (
    <section id='home' className='max-w-8xl mx-auto px-2.5 pt-24 pb-20 md:pb-52 md:pt-52 relative overflow-hidden'>
      <div className='absolute left-0 top-0 w-full h-full bg-brand-gray-100/70 -z-10'></div>
      <img className='absolute -right-1/4 md:-right-1/4 lg:right-0 top-16 -z-20' src={require('../assets/img/hero.png')} alt="" />
      <div data-aos="fade-up" className='h-full flex flex-col items-center justify-center sm:px-5 md:px-16 lg:px-36 xl:px-60'>
        <h1 className='text-brand-primary-200 text-3xl md:text-4xl lg:text-5xl text-center font-bold mb-6'>
        Skytrain, Skyshuttle(Skytaxi), Sky Freight(SkyCargo) Strategic Development and Business Plan
        </h1>
        <p className='md:text-xl mb-5'>
        GA Skytrain Mobility Group LLC
        </p>
        <div className='flex flex-col md:flex-row items-stretch md:items-center gap-5'>
          <button className='relative flex items-center text-brand-primary-200 gap-x-2 px-8 py-2 border-2 border-brand-primary-100 capitalize overflow-hidden group hover:text-white rounded-full transition-colors duration-500' onClick={() => handleOpen(1)}>
            <span className={`absolute -left-full group-hover:left-0 top-0 w-full h-full -z-10 transition-all duration-500 ease-in-out bg-brand-primary-100`}></span>
            <BsPlayCircle className='text-lg' />
            <span>watch Skytrain</span>
          </button>
          <button className='relative flex items-center text-brand-primary-200 gap-x-2 px-8 py-2 border-2 border-brand-primary-100 capitalize overflow-hidden group hover:text-white rounded-full transition-colors duration-500' onClick={() => handleOpen(2)}>
            <span className={`absolute -left-full group-hover:left-0 top-0 w-full h-full -z-10 transition-all duration-500 ease-in-out bg-brand-primary-100`}></span>
            <BsPlayCircle className='text-lg' />
            <span>watch Skytaxi</span>
          </button>
          <button className='relative flex items-center text-brand-primary-200 gap-x-2 px-8 py-2 border-2 border-brand-primary-100 capitalize overflow-hidden group hover:text-white rounded-full transition-colors duration-500' onClick={() => handleOpen(3)}>
            <span className={`absolute -left-full group-hover:left-0 top-0 w-full h-full -z-10 transition-all duration-500 ease-in-out bg-brand-primary-100`}></span>
            <BsPlayCircle className='text-lg' />
            <span>watch Smart Freight</span>
          </button>
        </div>
      </div>
      
      {active && <Modal setOpen={setActive} modalNum={modalNum} />}
    </section>
  )
}

// modal for video
const Modal = ({setOpen, modalNum}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // After 2000 milliseconds (2 seconds), update the state to show the message
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if(loading){
    return (
      <div className='fixed top-0 left-0 w-full h-full bg-black/90 z-50 flex items-center justify-center'>
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      </div>
    )
  }

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black/90 z-50 flex items-center justify-center'>
      <button onClick={() => setOpen(false)} className='absolute top-5 right-5 md:top-20 md:right-20 text-white text-2xl'>
        <AiOutlineCloseCircle />
      </button>
      <div className='max-w-8xl bg-brand-primary-100 p-1 rounded-md'>
      {/* <video src={require("../assets/video/video-2.mp4")} controls classname="w-[350px] h-[350px] md:w-[731px] md:h-[400px]"></video> */}
        {modalNum === 1 && <div className='w-[350px] h-[350px] md:w-[731px] md:h-[400px]'><iframe title="videoplayback" src="https://streamable.com/e/myrojo" frameBorder={0} width="100%" height="100%" allowFullScreen  /></div>}
        {modalNum === 2 && <div className='w-[350px] h-[350px] md:w-[731px] md:h-[400px]'><iframe src="https://streamable.com/e/050st9" title="videoplayback2" frameborder="0" width="100%" height="100%" allowfullscreen ></iframe></div>}
        {modalNum === 3 && <div className='w-[350px] h-[350px] md:w-[731px] md:h-[400px]'><iframe title="videoplayback3" src="https://streamable.com/e/2f3s5y" frameborder="0" width="100%" height="100%" allowfullscreen ></iframe></div>}
      </div>
    </div>
  )
}

export default Hero
