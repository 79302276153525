import React from 'react'

const SectionTitle = ({title}) => {
  return (
    <div data-aos="fade-up">
      <h3 className='text-brand-primary-200 font-bold text-xl md:text-2xl text-left mb-4'>
        {title}
      </h3>
      <span className='block w-10 md:w-16 h-0.5 md:h-1 bg-brand-primary-100 mb-5 md:mb-10'></span>
    </div>
  )
}

export default SectionTitle
