import { useEffect } from "react";
import AOS from "aos";

import AirRail from "./components/AirRail";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Market from "./components/Market";
import SmartFreight from "./components/SmartFreight";
import SmartTram from "./components/SmartTram";
// aos style
import "aos/dist/aos.css";

function App() {
  
  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      offset: 50,
      delay: 300,
      duration: 1000
    });
  }, []);
  
  useEffect(() => {
    AOS.refresh();
  }, []);
  return (
    <>
      <Header />
      <main>
        <Hero />
        <AirRail />
        <SmartTram />
        <SmartFreight />
        <Market />
      </main>
      <Footer />
    </>
  );
}

export default App;
