import React from 'react'
import { LuCircleOff } from "react-icons/lu"
import { BsPlayCircleFill } from "react-icons/bs"
import { TramData } from "../lib/data"
import Palnds from "../assets/img/plan-bg.png"
import SectionTitle from './SectionTitle'

const SmartTram = () => {
  return (
    <section id='tram' className='max-w-8xl mx-auto px-2.5 py-20 bg-white rounded-3xl shadow-xl mb-5'>
      <div data-aos="fade-up" className='mb-10'>
        <h2 className='text-2xl md:text-3xl text-brand-primary-200 font-bold text-center capitalize mb-5'>
        Skytaxi - PRT Smart Tram
        </h2>
        <span className='block w-14 md:w-20 mx-auto h-0.5 md:h-1 bg-brand-primary-100 mb-5'></span>
        <p className='text-center text-sm md:text-lg'>
        The world's first suspended PRT smart system
        </p>
      </div>
      <div data-aos="fade-up" className='relative w-full mb-10'>
        <video className='w-full max-h-[500px] min-h-[400px] object-cover rounded-3xl overflow-hidden' src={require('../assets/video/video-1.mp4')} autoPlay loop muted></video>
        <div className='absolute top-0 rounded-3xl left-0 w-full h-full bg-black/80 z-10'></div>
        <div className='absolute w-full md:w-auto px-10 top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2'>
          <h3 className='text-white md:text-xl font-bold text-center mb-5'>
            Introduction - the world's leading
            PRT MRT transportation
          </h3>
          <p className='text-white/90 text-sm md:text-lg text-center'>
            Aerial electric vehicle (aka suspended PRT convenient
            transportation system), a traffic system that aims to avoid the
            congested road traffic and implement the point-to-point fast
            and direct transportation in the air.
          </p>
        </div>
      </div>
      <div className='md:px-16 mb-10'>
        <SectionTitle title={'Features and Advantages'} />
        {/* blocks */}
        <div className='space-y-8'>
          {TramData.map((block, i) => (
            <div data-aos="fade-up" key={i} className={`flex flex-col ${i%2===0 ? 'lg:flex-row': 'lg:flex-row-reverse'} gap-8 items-center`}>
              <div className='lg:w-1/2'> 
                <h4 className='font-bold flex justify-center lg:justify-start items-start lg:items-end gap-4 mb-4'>
                  <LuCircleOff className="font-normal text-2xl" />
                  {block.title}
                </h4>
                <p className='indent-4 text-sm md:text-base text-justify'>
                  {block.text}
                </p>
              </div>
              <div className='overflow-hidden rounded-lg shadow-lg lg:w-1/2'>
                <img className='hover:scale-110 transition-all duration-500 ease-in-out' src={block.img} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* application */}
      <Applications />
      {/* R&D plan road map */}
      <Plan />
    </section>
  )
}

const Applications = () => {
  return (
    <div className='md:px-16 mb-10 lg:pb-10 xl:pb-0'>
      <h3 className='text-brand-primary-200 font-bold text-xl md:text-2xl text-left mb-4'>
        Functions and Applications
      </h3>

      <div data-aos="fade-up" className='flex flex-col-reverse lg:flex-row items-center justify-between'>
        <div className='space-y-2 lg:w-1/2 lg:mt-10'>
          <h4 className='font-bold flex justify-center lg:justify-start items-start gap-4 mb-4'>
            <LuCircleOff className="font-normal text-2xl" />
            <span className='flex-1'>
            Air electric vehicles are lightweight, low-volume per vehicle transportation systems
            Widely applicable, examples including but not limited to:
            </span>
          </h4>
          <p className='text-sm md:text-base text-justify'>
              (1) Large-scale tourist attractions, theme parks and other round-trip lines or circular
              traffic between scenic spots
          </p>
          <p className='text-sm md:text-base text-justify'>
              (2)  Internal traffic lines of large parks, airports, etc.
          </p>
          <p className='text-sm md:text-base text-justify'>
              (3) Connection lines and extension lines of large-scale urban rail transit networks
          </p>
          <p className='text-sm md:text-base text-justify'>
              (4) Public transport in small and medium-sized cities
          </p>
        </div>
        <img className='flex-1 lg:w-1/2' src={require('../assets/img/tran.png')} alt="" />
      </div>
    </div>
  )
}

const Plan = () => {
  return (
    <div className='md:px-16 py-10'>
      <SectionTitle title={'R&D plan road map'} />
      <div data-aos="fade-up" className="relative lg:h-[460px] py-10">
        <img className='absolute object-cover md:object-contain h-full w-full left-0 top-10 z-0' src={Palnds} alt="plan" />
        <div className='relative flex flex-col lg:flex-row justify-between h-full items-center w-full z-10'>
          <div className='lg:w-1/3 mb-5'>
            <div className='flex flex-row justify-center lg:justify-start lg:flex-col gap-2 mb-4'>
              <img className='w-10' src={require('../assets/img/list.png')} alt="" />
              <h4 className='font-medium text-lg'>
              Patented technical barriers
              </h4>
            </div>
            <p className='text-center lg:text-justify'>
              Focus on core technology research and
              development, combine technological
              innovation and product innovation. Strive
              to transform patented technology into
              productivity.
            </p>
          </div>
          <div className='lg:w-1/4 flex lg:flex-col flex-wrap lg:flex-nowrap gap-x-5 gap-y-8 h-full justify-center sm:justify-between py-5'>
            <div className='flex items-start gap-2 w-2/3 sm:w-2/5 lg:w-full'>
              <span className='block mt-1 text-xl text-brand-primary-100'>
                <BsPlayCircleFill />
              </span>
              <div>
                <h5 className='font-semibold mb-2'>
                Elaborative test line:
                </h5>
                <p className=''>
                Comprehensively test, verify
                and improve the system
                </p>
              </div>
            </div>
            <div className='flex items-start gap-2 w-2/3 sm:w-2/5 lg:w-full'>
              <span className='block mt-1 text-xl text-brand-primary-100'>
                <BsPlayCircleFill />
              </span>
              <div>
                <h5 className='font-semibold mb-2'>
                Industry standard:
                </h5>
                <p className=''>
                Comprehensively standardize the
                technology of Skytrain (suspended
                PRT) transportation system
                </p>
              </div>
            </div>
            <div className='flex items-start gap-2 w-2/3 sm:w-2/5 lg:w-full'>
              <span className='block mt-1 text-xl text-brand-primary-100'>
                <BsPlayCircleFill />
              </span>
              <div>
                <h5 className='font-semibold mb-2'>
                Operation demonstration line:
                </h5>
                <p className=''>
                  Fully demonstrate the commercial
                  operation of smart air-connected
                  vehicles
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartTram
