import React from 'react'
import Development from './Development'
// import Team from './Team'
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// // import required modules
// import { Autoplay, Pagination } from 'swiper/modules';
import SectionTitle from './SectionTitle';

const AirRail = () => {
  return (
    <section id='rail' className='max-w-8xl mx-auto px-2.5 pt-20 pb-20 md:pt-0'>
      <div className='mb-10'>
        <h2 className='text-2xl md:text-3xl text-brand-primary-200 font-bold text-center capitalize mb-5'>
        Skytaxi Air Rail Introduction
        </h2>
        <span className='block w-14 md:w-20 mx-auto h-0.5 md:h-1 bg-brand-primary-100 mb-5'></span>
        <p className='text-center text-sm md:text-lg'>
        Ranked number one in domestic contact rail transit and
        mobile power supply fields
        </p>
      </div>
      {/* rail block */}
      <Rail />
      {/* development blcock */}
      <Development />
      {/* our team */}
      {/* <Team /> */}
    </section>
  )
}

// rail block
const Rail = () => {
  return (
    <div className='mb-10'>
      <div className='mb-6'>
        <SectionTitle title={'Skytrain Air Rail'} />
        <p className='text-sm md:text-base'>
          Established in November 2013. A leading enterprise in the domestic contact rail transit
          and mobile power supply fields. Owns 62 patents in the field of mobile power supply,
          including 35 inventions.
          Core Supplier to CRRC, China Railway, ChinTang Rail, etc. Core member of National
          Maglev Traffic Engineering Technology Research Center.
          Sole solution provider of shoe rail integration in medium and low-speed maglev transit,
          high-speed maglev transit, straddle monorail, suspended monorail, etc.
          Mobile power supply and contact rail technology provider and product supplier for the
          planned Shanghai-Hangzhou high-speed maglev and Guangzhou-Shenzhen high-speed
          maglev (600KM per hour).
        </p>  
      </div>
    </div>
  )
}

export default AirRail
