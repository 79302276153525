import img1 from "../assets/img/managment.png"
import img2 from "../assets/img/research.png"
import img3 from "../assets/img/designer.png"
import img4 from "../assets/img/manufacturing.png"
import img5 from "../assets/img/service.png"
import feaucher1 from "../assets/img/feaucher1.jpg"
import feaucher2 from "../assets/img/feaucher2.jpg"
import feaucher3 from "../assets/img/feaucher3.jpg"
import feaucher4 from "../assets/img/feaucher4.jpg"
import feaucher5 from "../assets/img/feaucher5.jpg"
import feaucher6 from "../assets/img/feaucher6.jpg"

// icons 
import { MdOutlineWifi } from "react-icons/md"
import { FaComputer, FaShareNodes } from "react-icons/fa6"
// import {  } from "react-icons/fa"
import { BsPlayBtnFill } from "react-icons/bs"

export const DevelopmentData = [
  {
    years: "2000-2002",
    text: ["The first rigid catenary in China", "The first application of domestic contact rail in medium and low"]
  },
  {
    years: "2013-2014",
    text: ["Provincial and Ministerial Project achievement appraisal on C-shaped track"]
  },
  {
    years: "2005-2009",
    text: ["- Application of high-speed maglev contact rail system (863 projects)", "- Switch-free contact rail system"]
  },
  {
    years: "2016",
    text: ["- Investment on infrastructure", "- Designed new Straddle Monorail in Wuhu City", "- Seamless contact rail system", "- First National Monorail Center"]
  },
  {
    years: "2015",
    text: ["Changsha (4.8M population) maglev supply and service contract signed"]
  },
  {
    years: "2017",
    text: ["600km/h high-speed maglev transportation", "160km/h Medium-speed maglev transportation", "CRRC Quartet Suspension Monorail", "CRRC Yangtze River Freight Monorail", "Kelana Jaya rail line in Malaysia International", "Monorail Annual Conference"]
  },
]

export const TeamData = [
  {
    img: img1,
    name: "Holden Caulfield",
    job: "managment"
  },
  {
    img: img2,
    name: "Tom Caulfield",
    job: "research & development"
  },
  {
    img: img3,
    name: "Jerry Caulfield",
    job: "Designing"
  },
  {
    img: img4,
    name: "Jack",
    job: "Manufacturing"
  },
  {
    img: img5,
    name: "Holden Caulfield",
    job: "Service"
  },
]

export const TramData = [
  {
    title: "Independent, efficient and flexible right of way",
    text: "The modular smart tram runs overhead, it does not compete with people and vehicles, and has independent right of way. Vehicle operation is not restricted by ground traffic conditions and traffic lights. It has flexible station settings, on-demand departures, and public transportation that operates 24 hours daily.",
    img: feaucher1,
  },
  {
    title: "Safe, reliable, stable and comfortable",
    text: "Vehicles are equipped with a variety of safety rescue plans such as automatic inflatable sliding pad, rescue ferry board, rescue software, etc. to achieve high level of safety assurance. Using special rubber wheels, the control system ensures that vehicles run at the optimal acceleration speed under different working conditions. Passenger experience is smooth and comfortable.",
    img: feaucher2,
  },
  {
    title: "Convenient and fast with considerable volume",
    text: "The maximum running speed of the modular smart tram can reach 120km/h with 6 passengers. The network operation control system and vehicle safety protection system ensure that the vehicles are tracked and operated according to 2 seconds of headway. The transportation capacity can reach 6,000 people/hour- economical.",
    img: feaucher3,
  },
  {
    title: "Small footprint and high adaptability",
    text: "Does not compete with ground space. The minimum turning radius is 8m, the maximum climbing ability is 120%. The options of route selection is substantial. It also has high adaptability to complex maps.",
    img: feaucher4,
  },
  {
    title: "Intelligent control, autonomous operation",
    text: "The system adopts new communication signal control technology and driverless technology to implement precise modular control of vehicle-rail and vehiclevehicle communication signals, which can be automatically dispatched, automatically monitored, and automatically cruised.",
    img: feaucher5,
  },
  {
    title: "Low investment, low operation and maintenance cost",
    text: "Less investment, quicker results. The investment cost per kilometer is about one-tenth to one-fifteenth of subway, and one-sixth to one-eighth of tramrail. The operating cost is substantially lower than the existing rail transit system.",
    img: feaucher6,
  }
]

export const ComparisonTable = [
  {
    project: "Construction Cost",
    road: "80-160M RMB/KM",
    rail: "120-200M RMB/KM",
    smart: "80-120M RMB/KM"
  },
  {
    project: "Construction Period",
    road: "24 months",
    rail: "3-5 years",
    smart: "12 months"
  },
  {
    project: "Freight rate",
    road: "0.5-0.65RMB/T*km",
    rail: "0.28-0.35RMB/T*km",
    smart: "0.25~0.35RMB/T*km"
  },
  {
    project: "Operation Mode",
    road: "Single Container ",
    rail: "Marshalling",
    smart: "Single Container"
  },
  {
    project: "Flexibility",
    road: "High",
    rail: "Low",
    smart: "High"
  },
  {
    project: "Energy Consumption",
    road: "High",
    rail: "Low",
    smart: "Low"
  },
  {
    project: "Capacity",
    road: "Low",
    rail: "High",
    smart: "High"
  },
]

export const ExampleData = [
  {
    project: "construction line",
    parameters: "Qingdao Qianwan Port-Wangtaiyang Estuary"
  },
  {
    project: "construction mileage",
    parameters: "33 kilometers"
  },
  {
    project: "construction cost (vehicles included)",
    parameters: "3.96 billion RMB"
  },
  {
    project: "construction period",
    parameters: "12-18 months"
  },
  {
    project: "shipping container specifications",
    parameters: "mainly 20 feet and 40 feet containers"
  },
  {
    project: "cargo volume",
    parameters: "5 million TEU/year (125 million tons/year)"
  },
  {
    project: "capacity",
    parameters: "10 million TEU/year (250 million tons/year）"
  },
]

export const marketData = [
  {
    title: "Applicable Area",
    icon: <MdOutlineWifi className='text-4xl text-brand-primary-100' />,
    text: "Small and medium-sized cities (less than one million people), tourist attractions, integrated development of urban and rural areas. Ports, hazardous chemicals transportation, multimodal transportation, logistics transportation"
  },
  {
    title: "Market Analysis",
    icon: <FaComputer className='text-4xl text-brand-primary-100' />,
    text: "There are more than 8,000 cities and towns suitable for the construction of new rail transit in foreign countries, and tens of thousands of tourist attractions"
  },
  {
    title: "Target Market",
    icon: <FaShareNodes className='text-4xl text-brand-primary-100' />,
    text: "Tourist towns, urban-rural integration areas, industrial parks, and densely populated areas in cities."
  },
  {
    title: "Market Development",
    icon: <BsPlayBtnFill className='text-4xl text-brand-primary-100' />,
    text: "Four routes including Laojieling Scenic Area in Henan Province, Yuntai Mountain Scenic Area, Shaolin Temple Scenic Area, and Liangzi Island Scenic Area in Ezhou, Hubei Province have started construction. Qingdao Qianwan Port project is scheduled to start."
  },
]

export const industryData = [
  {
    id: 1,
    text: "Although the rail transit industry is a traditional industry, its technical barriers are relatively high."
  },
  {
    id: 2,
    text: "Sufficient reserves of technical talents, and a top-notch joint R&D team."
  },
  {
    id: 3,
    text: "The smart rail system has great advantages in terms of cost, comfort, and safety. Strongly competitive in the market."
  },
  {
    id: 4,
    text: "High level of barrier to entry, and the competitive advantage in the same field is outstanding."
  },
]

export const competitorsTable = [
  {
    category: "Technical Advantages",
    byd: "Straddle monorail vehicle",
    traditional: "Suspended monorail",
    skytaxi: "Micro track combined with automotive technology"
  },
  {
    category: "Mode",
    byd: "Package",
    traditional: "System integration",
    skytaxi: "Technology and system Integration"
  },
  {
    category: "Stability and Comfortability",
    byd: "Low",
    traditional: "High",
    skytaxi: "High"
  },
  {
    category: "Construction Cost",
    byd: "150-300M RMB/KM",
    traditional: "120-150M RMB/KM",
    skytaxi: "60-100M RMB/KM"
  },
  {
    category: "Finance (construction, operation)",
    byd: "Entirely dependent on government investment",
    traditional: "Entirely dependent on government investment",
    skytaxi: "Self-sufficient; rely on operation to achieve profitability"
  },
]

export const comparativeTable = [
  {
    category: "Route characteristics",
    tram: "Open (mixed right of way)",
    airbus: "Elevated lines, exclusive right-of-way",
    skytaxi: "Elevated lines, exclusive right-ofway"
  },
  {
    category: "Minimum turning radius (m)",
    tram: "30",
    airbus: "15",
    skytaxi: "8"
  },
  {
    category: "Gradeability",
    tram: "60 per-mille",
    airbus: "60 per-mille",
    skytaxi: "120 per-mille"
  },
  {
    category: "cost per kilometer (100 million RMB)",
    tram: "1-1.2",
    airbus: "1.3~1.5",
    skytaxi: "0.5~0.8"
  },
  {
    category: "one-way capacity (10,000 people/h)",
    tram: "0.5-1",
    airbus: "0.3~0.6",
    skytaxi: "0.3~0.6/0.6~1.2 (single/double)"
  },
  {
    category: "average speed (km/h) ",
    tram: "15-25",
    airbus: "15-40",
    skytaxi: "50-80"
  },
  {
    category: "Route footprint",
    tram: "Large in closed section",
    airbus: "Large",
    skytaxi: "Small"
  },
  {
    category: "Investment scale",
    tram: "Large",
    airbus: "Medium",
    skytaxi: "Small"
  },
  {
    category: "Operation and maintenance cost",
    tram: "Medium",
    airbus: "Medium",
    skytaxi: "Low"
  },
]