import React from 'react'
import { DevelopmentData } from '../lib/data'
import SectionTitle from './SectionTitle'

const Development = () => {
  return (
    <>
      <div className='flex items-center py-10'>
        <span className='w-1/2 block border-2 border-brand-primary-100 border-dashed' ></span>
        <h3 className='md:text-lg mx-5 text-brand-primary-200 font-bold capitalize'>development</h3>
        <span className='w-1/2 block border-2 border-brand-primary-100 border-dashed' ></span>
      </div>
      <div className='mb-5'>
        <SectionTitle title={'Focus on innovation. Always climbing.'} />

        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-8 pt-10'>
          {DevelopmentData.map((item,i) => (
            <div data-aos="fade-up" key={i} className='flex flex-col md:flex-row items-center gap-4'>
              <span className='flex shadow-100 items-center justify-center text-sm md:text-base font-medium w-28 h-28 rounded-full border-2 border-white bg-brand-gray-200'>
                {item.years}
              </span>
              <div className='flex-1'>
                {/* <h4 className='font-bold md:text-lg'>{item.years}</h4> */}
                {item.text.map((t,k) => (
                  <p className='text-sm md:text-base text-center md:text-left' key={k}>
                    {t}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Development
